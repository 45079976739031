<!-- start chat conversation section -->
<div    class="user-chat w-100 overflow-hidden user-chat-show" [class.user-chat-show]="hideUserChatValue">
    <div class="w-100 overflow-hidden position-relative" style="width: 100%;">
        <div class="p-3 p-lg-4 border-bottom user-chat-topbar">
            <div class="row align-items-center">
                <div class="col-sm-11 col-11">
                    <div class="d-flex align-items-center">
                        <div class="d-block d-lg-none me-2 ms-0">
                            <a routerLink="" class="user-chat-remove text-muted font-size-16 p-2"><i
                                    class="ri-arrow-left-s-line" (click)="hideUserChatOnMObile()"></i></a>
                        </div>
                        <div class="chat-user-img   align-self-center me-3 ms-0" [class.offline]="!this.connected"
                            [class.online]="this.connected ">
                            <a [href]="'/mon-espace?openannonce='+idAnnonce" target="_blank">
                                <img
                                        [src]="image"
                                        [ngClass]="{'fast': isFastConnect}"
                                        class=" avatar-xs" alt=""></a>
                            <span class="user-status"></span>
                        </div>
                        <div class="overflow-hidden">
                            <h5 class="font-size-16 mb-0 text-truncate"><a [href]="'/mon-espace?openannonce='+idAnnonce"
                                    class="text-reset user-profile-show" target="_blank">{{ size > 425 ? 'Discussion avec' :''}}
                                    {{prenom}} {{nomUser}}</a>
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 col-1">
                    <ul class="list-inline user-chat-nav text-end mb-0">
                        <li class="list-inline-item">
                            <div class="dropdown">
                                <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end" style="min-width: 15rem;">

                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                        data-bs-target='#deleteModal'>Supprimer
                                        la discussion<i class="ri-delete-bin-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                        data-bs-target='#blockModel'>Bloquer ce contact
                                        <i class="ri-forbid-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" data-bs-toggle="modal" style="cursor: pointer;"
                                        data-bs-target='#reportModal'>Signaler cette personne
                                        <i class="ri-forbid-line float-end text-muted"></i></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- end chat user head -->






        <!-- end chat input section -->
        <!-- start chat conversation -->
        <div class="chat-conversation p-3 p-lg-4" data-simplebar="init">
            <ul class="list-unstyled mb-0">


                <!-- <li>
                    <div class="chat-day-title">
                        <span class="title">Today</span>
                    </div>
                </li> -->

                <div *ngFor="let conversation of  listConversation;let i =index; let last=last">

                    <li *ngIf="conversation.id_expediteur !== this.currentUserId ">
                        <div class="conversation-list" style="margin-bottom: 2px;">

                            <div class="user-chat-content">
                                <p class="message-highlight" *ngIf="conversation?.express === 1">Message Contact Express</p>
                                <div class="ctext-wrap">

                                    <div class="ctext-wrap-content">
                                        <p class="mb-0"
                                           [class]="'************'===conversation?.contenu?'blur_text':''"
                                           [innerHTML]="transform(conversation.contenu, conversation)">
                                        </p>
                                        <a *ngIf="'************'===conversation?.contenu"
                                           class="link_p"
                                           [href]="environment.subscribeUrl + '?p=1&membre=' + conversation.expediteur.pseudo"
                                        >Lire la suite</a>
                                        <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                                                class="align-middle"> {{ isToday(conversation?.date_envoi) ?
                                                (conversation?.date_envoi | date:
                                                "HH:mm" ) :( conversation?.date_envoi | date: "dd/MM/yyyy HH:mm" :'fr')
                                                }}</span>
                                        </p>
                                    </div>

                                </div>

                                <div class="chat-avatar"
                                    *ngIf="listConversation[i]?.id_destinataire !== listConversation[i +1]?.id_destinataire ">

                                    <img
                                            [src]="userService.getImageProfilPath(conversation?.expediteur.sexe,conversation?.expediteur.photo_profil,conversation?.expediteur.is_fast_connect)"
                                            [ngClass]="{'fast': conversation?.expediteur.is_fast_connect}"
                                         alt="">
                                </div>
                                <div class="chat-avatar"
                                    *ngIf="listConversation[i]?.id_expediteur === listConversation[i +1]?.id_expediteur ">
                                    <img [src]="" style="display: none;">
                                </div>

                                <div class="conversation-name" *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur
                                    ">
                                    {{prenom}} {{nomUser}}</div>

                                <!-- New Title and Buttons -->
                                <div *ngIf="conversation?.express === 1 && last" class="response-express">
                                    <p class="title-highlight">Ma réponse Express</p>
                                    <div class="response-express-body" >
                                        <button (click)="handleResponseYes()" class="btn-oui"><i class="ri-thumb-up-fill"></i> Oui</button>
                                        <button (click)="handleResponseNo()" class="btn-non"><i class="ri-thumb-down-fill"></i> Non</button>
                                    </div>
                                </div>
                                <!-- End of New Title and Buttons -->
                            </div>

                        </div>
                    </li>

                    <li class="right" *ngIf="conversation.id_expediteur===this.currentUserId  ">
                        <div class="conversation-list" style="margin-bottom: 2px;">

                            <div class="user-chat-content">
                                <div class="ctext-wrap">
                                    <div class="ctext-wrap-content">
                                        <p class="mb-0"
                                           [class]="'************'===conversation?.contenu?'blur_text':''"
                                           [innerHTML]="transform(conversation.contenu, conversation)">
                                        </p>
                                        <a *ngIf="'************'===conversation?.contenu"
                                           class="link_p"
                                           [href]="environment.subscribeUrl + '?p=1&membre=' + conversation.destinataire.pseudo"
                                        >Lire la suite</a>
                                        <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                                                class="align-middle">{{ isToday(conversation?.date_envoi) ?
                                                (conversation?.date_envoi | date:
                                                "HH:mm" :'fr') :( conversation?.date_envoi | date: "dd/MM/yyyy HH:mm"
                                                :'fr')
                                                }}</span>
                                        </p>

                                        <p *ngIf="last && conversation.lu_destinataire != 0" class="chat-time mb-0">
                                            <span class="vu"> Lu le </span> <span class=" vu_date">{{
                                                ( conversation?.date_lecture | date: "dd/MM/yyyy HH:mm" :'fr')
                                                }}</span>
                                        </p>

                                    </div>

                                </div>
                                <div class="chat-avatar"
                                    *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur ">
                                    <img [src]="userService.getImageProfilPath(conversation?.expediteur.sexe,conversation?.expediteur.photo_profil,conversation?.expediteur.is_fast_connect)"
                                         [ngClass]="{'fast': conversation?.expediteur.is_fast_connect}"
                                        alt="">
                                </div>
                                <div class="chat-avatar"
                                    *ngIf="listConversation[i]?.id_expediteur === listConversation[i +1]?.id_expediteur ">
                                    <img [src]="" style="display: none;">
                                </div>
                                <div class="conversation-name" *ngIf="listConversation[i]?.id_expediteur !== listConversation[i +1]?.id_expediteur
                                    ">
                                    {{
                                    conversation.expediteur.prenom?conversation.expediteur.prenom:conversation.expediteur.pseudo}}
                                    {{ conversation.expediteur.nom}}</div>

                            </div>

                        </div>
                    </li>

                </div>


            </ul>


            <div [style.height]="size > 768 ?'30px':'130px'" #scrollBottom> </div>
        </div>
        <!-- end chat conversation end -->

        <!-- start chat input section -->
        <div class="chat-input-section p-2  p-lg-4 border-top mb-0" [class.p-lg-4]="size > 768">

            <div class="row g-0">
                <div class="col">
                    <textarea type="text" name="messageLg" #messageLg="ngModel" [(ngModel)]="conversationData.contenu "
                        class=" form-control form-control-lg bg-light border-light message-input"
                        placeholder="Ecrire un message..."></textarea>
                </div>
                <div class="col-auto">
                    <div class="chat-input-links ms-md-2 me-md-0">
                        <ul class="list-inline mb-0">

                            <li class="list-inline-item">
                                <button id="submit" type="submit" (click)="addMessage(conversationData)"
                                    class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                                    <i class="ri-send-plane-2-fill"></i>
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>

    </div>

    <div class="modal fade" id="modal-express" tabindex="-1" #modal4 role="dialog"
         aria-labelledby="addgroup-exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
                        Votre réponse est bien partie, son destinataire pourra la lire !</h5>
                </div>
                <div class="modal-body p-4">
                    <div class="mb-3">
                        <p class="body-modal">
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closeModal()">Fermer</button>
                </div>
            </div>
        </div>
    </div>

</div>


